import {Alert} from '@mui/lab'
import {PageTitle} from '_metronic/layout/core'
import AsInstall from 'pages/install/component/AsInstall'
import Install from 'pages/install/component/install'
import {useEffect, useLayoutEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {positionAction} from 'redux/action'
import {getInstallAllCount, getPosition} from 'utils/apiCalling/api'
import InstallAsStatus from './component/InstallAsStatus'
import InstallStatus from './component/InstallStatus'
import InstallHeader from './layout/InstallHeader'

const InstallPage = (props: any) => {
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const dispatch = useDispatch()
  const location = useLocation()
  const [count, setCount] = useState<any>(null)
  const [getPositionAction, setPositionAction] = useState<any>(false)
  const [errPosition, setErrPosition] = useState<any>(false)
  const getInstallAllCountCall = () => {
    getInstallAllCount(userInfo, {
      success: (res: any) => {
        setCount(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
    })
  }
  useLayoutEffect(() => {
    getInstallAllCountCall()
  }, [])

  useEffect(() => {
    if (location.pathname === '/install/connect' || location.pathname === '/install/as') {
      getPosition({
        success: async (res: any) => {
          // await SuccessAlert('위치정보를 업데이트 하였습니다.')
          console.log('res', res.coords)
          setPositionAction(true)
          dispatch(
            await positionAction('update', {
              latitude: res?.coords?.latitude,
              longitude: res?.coords?.longitude,
            })
          )
          setTimeout(() => {
            setPositionAction(false)
          }, 1800)
        },
        fail: async (err: any) => {
          // await ErrorAlert(err)
          setErrPosition(true)
          setTimeout(() => {
            setErrPosition(false)
          }, 1800)
        },
      })
    }
  }, [location])

  return (
    <>
      {errPosition && (
        <Alert
          severity='error'
          style={{position: 'absolute', width: '100%', top: 0, left: 0, zIndex: 9999, fontSize: 20}}
        >
          위치정보를 업데이트 할 수 없습니다.
        </Alert>
      )}
      {getPositionAction && (
        <Alert
          severity='success'
          style={{position: 'absolute', width: '100%', top: 0, left: 0, zIndex: 9999, fontSize: 20}}
        >
          위치정보를 업데이트 하였습니다.
        </Alert>
      )}
      <Routes>
        <>
          <Route
            element={
              <>
                <InstallHeader count={count} />
                <Outlet />
              </>
            }
          >
            <Route
              path={'connect'}
              element={
                <>
                  <PageTitle>설치</PageTitle>
                  <Install countCall={getInstallAllCountCall} />
                </>
              }
            />
            <Route
              path={'status'}
              element={
                <>
                  <PageTitle>설치 현황</PageTitle>
                  <InstallStatus countCall={getInstallAllCountCall} />
                </>
              }
            />
            <Route
              path={'as'}
              element={
                <>
                  <PageTitle>{`A/S`}</PageTitle>
                  <AsInstall countCall={getInstallAllCountCall} />
                </>
              }
            />
            <Route
              path={'as/complete'}
              element={
                <>
                  <PageTitle>{`A/S 완료`}</PageTitle>
                  <InstallAsStatus />
                </>
              }
            />
          </Route>
        </>
      </Routes>
    </>
  )
}
export default InstallPage
