import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import {Backdrop, Button, Chip, MenuItem, Modal, Select, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import {envBackHost, envImagePath} from 'GLOBAL/envVariables'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {yMarker} from 'assets/imageDeclear'
import dayjs from 'dayjs'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {confirmAlert, normalAlert, resultAlert} from 'utils/CustomAlert/Alert'
import {entimoreController, globalFormatWithHyphens} from 'utils/const/globalConst'
import './print.css'

const customerUpdateDataInit = {
  customerNumber: null,
  officeId: null,
  customerTypeId: null,
  customerWorkTypeId: null,
  customerName: null,
  addressRoad: null,
  addressNumber: null,
  addressDetail: null,
  phoneNumber: null,
  managerName: null,
  filter2: null,
  meterNumber: null,
  meterDiameter: null,
  checkMonth: null,
  checkDay: null,
  memo: null,
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: !isMobileDevice() ? '70%' : '90%',
  height: !isMobileDevice() ? '90%' : '90%',
  bgcolor: 'background.paper',
  overflowY: 'auto',
  borderRadius: '12px',
  boxShadow: 24,
  p: !isMobileDevice() ? 4 : 2,
}
const CustomerDetailInfoModal = (props: any) => {
  const userInfo = useSelector((state: any) => state?.userInfo)
  const {data, open, onClose, getList} = props
  const [updateFlag, setUpdateFlag] = useState(false)
  const [workType, setWorkType] = useState(null)
  const [customerType, setCustomerType] = useState(null)
  const [customerUpdateData, setCustomerUpdateData] = useState<any>(customerUpdateDataInit)

  const [entiDate, setEntiDate] = useState<any>(data?.imageIdxDate || '2020020001')

  const getWorkType = () => {
    axiosWithAuth()
      .get(`/office/${data?.officeId}/customerworktype`)
      .then((res) => {
        setWorkType(res.data.res)
      })
  }
  const getCustomerType = () => {
    axiosWithAuth()
      .get(`/office/${data?.officeId}/customertype`)

      .then((res) => {
        setCustomerType(res.data.res)
      })
  }

  const patchButton = (state: boolean) => {
    if (state) {
      console.log('fix now!')
    } else {
      console.log('cancled!')
      setCustomerUpdateData({
        customerNumber: data?.customerNumber,
        officeId: data?.officeId,
        customerTypeId: data?.customerTypeId,
        customerWorkTypeId: data?.customerWorkTypeId,
        addressRoad: data?.addressRoad,
        addressNumber: data?.addressNumber,
        addressDetail: data?.addressDetail,
        phoneNumber: data?.phoneNumber,
        managerName: data?.managerName,
        filter2: data?.filter2,
        meterNumber: data?.meterNumber,
        meterDiameter: data?.meterDiameter,
        checkMonth: data?.checkMonth,
        checkDay: data?.checkDay,
        memo: data?.memo,
        customerName: data?.customerName,
      })
    }
    setUpdateFlag(state)
  }

  const putCustomerUpdate = async () => {
    if (
      customerUpdateData.customerWorkTypeId === data.customerWorkTypeId &&
      customerUpdateData.customerTypeId === data.customerTypeId &&
      customerUpdateData.customerName === data.customerName &&
      customerUpdateData.phoneNumber === data.phoneNumber &&
      customerUpdateData.addressRoad === data.addressRoad &&
      customerUpdateData.addressNumber === data.addressNumber &&
      customerUpdateData.addressDetail === data.addressDetail &&
      customerUpdateData.managerName === data.managerName &&
      customerUpdateData.meterNumber === data.meterNumber &&
      customerUpdateData.meterDiameter === data.meterDiameter &&
      customerUpdateData.checkMonth === data.checkMonth &&
      customerUpdateData.checkDay === data.checkDay &&
      customerUpdateData.memo === data.memo &&
      customerUpdateData.filter2 === data.filter2
    ) {
      await normalAlert('수정된 내용이 없습니다.')
      return
    }
    if (await confirmAlert('수정', 'info')) {
      axiosWithAuth()
        .put(`${envBackHost}/customerinfo`, customerUpdateData)
        .then(async (res) => {
          console.log('!', res)
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            getList()
            setUpdateFlag(false)
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }

  useEffect(() => {
    getWorkType()
    getCustomerType()
    setCustomerUpdateData({
      customerNumber: data?.customerNumber,
      officeId: data?.officeId,
      customerTypeId: data?.customerTypeId,
      customerWorkTypeId: data?.customerWorkTypeId,
      addressRoad: data?.addressRoad,
      addressNumber: data?.addressNumber,
      addressDetail: data?.addressDetail,
      phoneNumber: data?.phoneNumber,
      managerName: data?.managerName,
      filter2: data?.filter2,
      meterNumber: data?.meterNumber,
      meterDiameter: data?.meterDiameter,
      checkMonth: data?.checkMonth,
      checkDay: data?.checkDay,
      memo: data?.memo,
      customerName: data?.customerName,
    })
    setEntiDate(data?.imageIdxDate || '2020020001')
  }, [data])

  useEffect(() => {
    if (data && data.latitude && data.longitude) {
      setTimeout(() => {
        let container = document?.getElementById('dashboard-detail-map')
        if (container) {
          const map = new window.kakao.maps.Map(container, {
            center: new window.kakao.maps.LatLng(data.latitude, data.longitude),
            mapTypeId: window.kakao.maps.MapTypeId.HYBRID,
            level: 1,
            draggable: false,
            scrollwheel: false,
            zoomable: false,
          })
          // const mapTypeControl = new window.kakao.maps.MapTypeControl()
          // map.addControl(mapTypeControl, window.kakao.maps.ControlPosition.TOPRIGHT)
          var markerPosition = new window.kakao.maps.LatLng(data.latitude, data.longitude)

          var marker = new window.kakao.maps.Marker({
            position: markerPosition,
            image: new window.kakao.maps.MarkerImage(yMarker, new window.kakao.maps.Size(40, 59)),
          })

          marker.setMap(map)
        }
      }, 300)
    }
  }, [data])

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose()
        setUpdateFlag(false)
        setCustomerUpdateData(customerUpdateDataInit)
      }}
      aria-labelledby={`${window.location.pathname}-label`}
      aria-describedby={`${window.location.pathname}-description`}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      id={'printTarget'}
      className={'printTarget'}
    >
      <Box sx={style}>
        <>
          {!isMobileDevice() && (
            <div>
              <div>
                <div className=''>
                  <div className={'w-100 d-flex justify-content-between'}>
                    <Chip
                      label={
                        <span>
                          <strong className={'text-primary'}>{data?.customerName}</strong> 수용가
                          상세정보 {data?.managerName}
                        </span>
                      }
                      variant={'outlined'}
                      sx={{fontSize: '20px', fontWeight: 'bold'}}
                    />
                    {/*<Button variant={'contained'} onClick={onClose}>*/}
                    <CancelPresentationIcon
                      // className={'point-cursor'}
                      sx={{fontSize: '40px', cursor: 'pointer'}}
                      color={'error'}
                      onClick={() => {
                        onClose()
                        setUpdateFlag(false)
                        setCustomerUpdateData(customerUpdateDataInit)
                      }}
                    />
                    {/*</Button>*/}
                  </div>

                  <div className=''>
                    {data && (
                      <>
                        <div className={'d-flex gap-6 col'}>
                          <div className={'col-5'}>
                            <div className={' border border-1 border-gray-400 shadow-sm px-0'}>
                              <Paper
                                className={
                                  'p-4 border-bottom border-1 border-gray-400 d-flex justify-content-between '
                                }
                                square
                              >
                                <h2 className={'align-self-center'}>수용가 정보</h2>
                                <div className={'d-flex gap-3'}>
                                  <Button
                                    variant={'outlined'}
                                    color={'success'}
                                    onClick={() => putCustomerUpdate()}
                                    hidden={!updateFlag}
                                  >
                                    수정완료
                                  </Button>
                                  {userInfo?.userInfo.accountType !== 'gov_meterman' && (
                                    <Button
                                      variant={'outlined'}
                                      color={!updateFlag ? 'error' : 'warning'}
                                      onClick={
                                        () => patchButton(!updateFlag)
                                        // setUpdateFlag(!updateFlag)
                                      }
                                    >
                                      {!updateFlag ? '수정' : '취소'}
                                    </Button>
                                  )}
                                </div>
                              </Paper>
                              <Paper
                                className={'p-4'}
                                square
                                style={{maxHeight: 'calc(100vh - 300px)', overflow: 'auto'}}
                              >
                                <div className={'row gap-2'}>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 사업소
                                    </h2>

                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.officeName}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 수용가 번호
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={globalFormatWithHyphens(
                                        data?.officeId,
                                        data?.customerNumber
                                      )}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 설치 날짜
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={dayjs(data?.meterReadInstallDate).format('YYYY-MM-DD')}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 수용가 종류
                                    </h2>
                                    <Select
                                      labelId='demo-simple-select-label'
                                      id='customer-target'
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data.customerTypeId}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      sx={{width: 319}}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          customerTypeId: e.target.value,
                                        })
                                      }}
                                    >
                                      {_.map(customerType, (el: any, i) => {
                                        return (
                                          <MenuItem key={i} value={el.idx}>
                                            {el.customerTypeName}
                                          </MenuItem>
                                        )
                                      })}
                                    </Select>
                                  </div>

                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 수용가 업종
                                    </h2>
                                    <Select
                                      labelId='demo-simple-select-label'
                                      id='customer-workType'
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data.customerWorkTypeId}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      sx={{width: 319}}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          customerWorkTypeId: e.target.value,
                                        })
                                      }}
                                    >
                                      {_.map(workType, (el: any, i) => {
                                        return (
                                          <MenuItem key={i} value={el.idx}>
                                            {el.customerWorkTypeName}
                                          </MenuItem>
                                        )
                                      })}
                                    </Select>
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 이름
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data?.customerName}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          customerName: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 전화번호
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data?.phoneNumber}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        if (e.target.value?.includes('-')) {
                                          e.target.value = e.target.value.replaceAll('-', '')
                                        }
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          phoneNumber: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 도로명 주소
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data?.addressRoad}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          addressRoad: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 지번 주소
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data?.addressNumber}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          addressNumber: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 상세주소
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data?.addressDetail}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          addressDetail: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 담당자
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data?.managerName}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          managerName: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 담당자 전화번호
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data?.filter2}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          filter2: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 계량기 번호
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      defaultValue={data?.meterNumber}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          meterNumber: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 구경 (단위 : mm)
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      defaultValue={data?.meterDiameter}
                                      type={'number'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          meterDiameter: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 검침일
                                    </h2>
                                    <div className={'d-flex gap-2 pe-2'}>
                                      <Select
                                        labelId='demo-simple-select-label'
                                        id='customer-checkMonth'
                                        sx={{width: 216}}
                                        className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                        defaultValue={data?.checkMonth === '당월' ? 10 : 20}
                                        inputProps={{
                                          readOnly: !updateFlag,
                                        }}
                                        onChange={(e) => {
                                          setCustomerUpdateData({
                                            ...customerUpdateData,
                                            checkMonth: e.target.value === 10 ? '당월' : '전월',
                                          })
                                        }}
                                      >
                                        <MenuItem value={10}>당월</MenuItem>
                                        <MenuItem value={20}>전월</MenuItem>
                                      </Select>
                                      <TextField
                                        variant={'outlined'}
                                        className={!updateFlag ? 'bg-gray-300' : ''}
                                        defaultValue={data?.checkDay}
                                        sx={{width: 90}}
                                        type={'number'}
                                        inputProps={{
                                          readOnly: !updateFlag,
                                        }}
                                        onChange={(e) => {
                                          setCustomerUpdateData({
                                            ...customerUpdateData,
                                            checkDay: e.target.value,
                                          })
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      'd-flex col w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2 col-3'}>
                                      <DoubleArrowIcon /> 메모
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      sx={{width: 500}}
                                      defaultValue={data?.memo}
                                      type={'text'}
                                      className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          memo: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                </div>
                              </Paper>
                            </div>
                          </div>
                          <div className={'col'}>
                            <div className={'row gap-3'}>
                              <div
                                id='dashboard-detail-map'
                                style={{width: '100%', height: '50vh', cursor: 'pointer'}}
                                onClick={() => {
                                  window.open(
                                    `https://m.map.kakao.com/scheme/search?q=${data.addressRoad}&center=me`,
                                    '_blank'
                                  )
                                }}
                                className={'rounded-2 border-gray-400 border border-1'}
                              />
                              <div className={'text-end'}>
                                <span className={'text-danger fw-bold text-end'}>
                                  지도를 클릭하면 카카오맵으로 이동합니다.
                                </span>
                              </div>
                              <Chip
                                label={
                                  <span>
                                    연결된 단말기 :
                                    <strong className={'text-success'}>
                                      {' '}
                                      {data?.serialNumber}
                                    </strong>
                                  </span>
                                }
                                variant={'outlined'}
                                sx={{fontSize: '20px', fontWeight: 'bold'}}
                              />
                              <div className={'w-100 border border-gray-400 rounded-3 p-3'}>
                                <div className={'d-flex col'}>
                                  <div className={'col row gap-1 text-center'}>
                                    <div>
                                      {data?.filePath && (
                                        <img
                                          src={envImagePath + data?.filePath}
                                          alt='metering-image'
                                          style={{transform: `rotate(${data?.rotate}deg)`}}
                                          width={320}
                                          height={144}
                                        />
                                      )}
                                    </div>
                                    <div className={'mt-2 fw-bold fs-4'}>
                                      <span>
                                        {entimoreController.model === '2'
                                          ? dayjs(data?.efosConnectAt).format(
                                              'YYYY년 MM월 DD일 HH:mm:ss'
                                            )
                                          : dayjs(data?.deviceConnectAt).format(
                                              'YYYY년 MM월 DD일 HH:mm:ss'
                                            )}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      'd-flex col fw-bold fs-3 text-center align-items-self '
                                    }
                                  >
                                    <div className={'col-5 row gap-3 align-items-center'}>
                                      <span>단말기 상태</span>
                                      <span>펌웨어</span>
                                      {entimoreController.model !== '2' && <span>신호강도</span>}
                                      <span>서버주기</span>
                                    </div>
                                    <div className={'col row gap-3 text-start align-items-center'}>
                                      <span>
                                        {entimoreController.model === '2' ? (
                                          entiDate.slice(0, -4) == dayjs().format('YYYYMMDD') ? (
                                            <span className={'text-primary'}>정상</span>
                                          ) : (
                                            <span className={'text-danger'}>점검</span>
                                          )
                                        ) : data?.deviceStatus === true ? (
                                          <span className={'text-primary'}>정상</span>
                                        ) : (
                                          <span className={'text-danger'}>점검</span>
                                        )}
                                      </span>
                                      <span>{data?.deviceVersion}</span>
                                      {entimoreController.model !== '2' && (
                                        <span>{data?.signalStrength}dBm</span>
                                      )}
                                      <span>{data?.serverCycle} H</span>
                                    </div>
                                  </div>
                                </div>
                                <div className={'mt-5 fs-5 fw-bold ms-4 text-center'}>
                                  {entimoreController.model === '2' ? (
                                    <span className={'text-primary'}>
                                      업데이트 내용이 없습니다.
                                    </span>
                                  ) : data?.deviceApplyStatus === true ? (
                                    <span className={'text-danger'}>
                                      업데이트 대기 중인 단말기 입니다.
                                    </span>
                                  ) : (
                                    <span className={'text-primary'}>
                                      업데이트 내용이 없습니다.
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isMobileDevice() && (
            <>
              <div
                className={'w-100 d-flex justify-content-between gap-3 align-items-center  mb-2'}
              >
                <Chip
                  label={
                    <div className={'w-100 row'}>
                      <strong className={'text-primary'}>{data?.customerName}</strong>
                      <span>수용가 상세정보 </span>
                      <span>담당자 : {data?.managerName}</span>
                    </div>
                  }
                  variant={'outlined'}
                  sx={{fontSize: '14px', fontWeight: 'bold', height: '100%'}}
                />
                {/*<Button variant={'contained'} onClick={onClose}>*/}
                <CancelPresentationIcon
                  // className={'point-cursor'}
                  sx={{fontSize: '50px', cursor: 'pointer'}}
                  color={'error'}
                  onClick={() => {
                    onClose()
                    setUpdateFlag(false)
                    setCustomerUpdateData(customerUpdateDataInit)
                  }}
                />
                {/*</Button>*/}
              </div>

              <div className=''>
                {data && (
                  <>
                    <div className={'row gap-3 col'}>
                      <div className={'col'}>
                        <div className={'row mx-0 gap-2'}>
                          <div className={' border border-1 border-gray-400 shadow-sm px-0'}>
                            <Paper
                              className={
                                'p-4 border-bottom border-1 border-gray-400 d-flex justify-content-between '
                              }
                              square
                            >
                              <h2 className={'align-self-center'}>수용가 정보</h2>
                              <div className={'d-flex gap-3'}>
                                <Button
                                  variant={'outlined'}
                                  color={'success'}
                                  onClick={() => putCustomerUpdate()}
                                  hidden={!updateFlag}
                                >
                                  수정완료
                                </Button>
                                <Button
                                  variant={'outlined'}
                                  color={!updateFlag ? 'error' : 'warning'}
                                  onClick={() => setUpdateFlag(!updateFlag)}
                                >
                                  {!updateFlag ? '수정' : '취소'}
                                </Button>
                              </div>
                            </Paper>
                            <Paper className={'p-4 '} square>
                              <div className={'row gap-2'}>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 사업소
                                  </h2>

                                  <TextField
                                    variant={'outlined'}
                                    className={'mx-2 bg-gray-300'}
                                    value={data?.officeName}
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 수용가 번호
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    className={'mx-2 bg-gray-300'}
                                    value={data?.customerNumber}
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 수용가 종류
                                  </h2>
                                  <Select
                                    labelId='demo-simple-select-label'
                                    id='customer-target'
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data.customerTypeId}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    sx={{width: 319}}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        customerTypeId: e.target.value,
                                      })
                                    }}
                                  >
                                    {_.map(customerType, (el: any, i) => {
                                      return (
                                        <MenuItem key={i} value={el.idx}>
                                          {el.customerTypeName}
                                        </MenuItem>
                                      )
                                    })}
                                  </Select>
                                </div>

                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 수용가 업종
                                  </h2>
                                  <Select
                                    labelId='demo-simple-select-label'
                                    id='customer-workType'
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data.customerWorkTypeId}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    sx={{width: 319}}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        customerWorkTypeId: e.target.value,
                                      })
                                    }}
                                  >
                                    {_.map(workType, (el: any, i) => {
                                      return (
                                        <MenuItem key={i} value={el.idx}>
                                          {el.customerWorkTypeName}
                                        </MenuItem>
                                      )
                                    })}
                                  </Select>
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 이름
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data?.customerName}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        customerName: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 전화번호
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data?.phoneNumber}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      if (e.target.value?.includes('-')) {
                                        e.target.value = e.target.value.replaceAll('-', '')
                                      }
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        phoneNumber: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 도로명 주소
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data?.addressRoad}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        addressRoad: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 지번 주소
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data?.addressNumber}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        addressNumber: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 상세주소
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data?.addressDetail}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        addressDetail: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 계량기 번호
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data?.meterNumber}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        meterNumber: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 담당자
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data?.managerName}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        managerName: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 담당자 전화번호
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    defaultValue={data?.filter2}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        filter2: e.target.value,
                                      })
                                    }}
                                  />
                                </div>

                                <div
                                  className={'row w-100 justify-content-between align-items-center'}
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 구경 (단위 : mm)
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    defaultValue={data?.meterDiameter}
                                    type={'number'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        meterDiameter: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                                <div
                                  className={
                                    'row w-100 justify-content-between align-items-center px-0 mx-0'
                                  }
                                >
                                  <h2 className={'d-flex align-items-center gap-2'}>
                                    <DoubleArrowIcon /> 검침일
                                  </h2>
                                  <div className={'d-flex gap-2 '}>
                                    <Select
                                      labelId='demo-simple-select-label'
                                      id='customer-checkMonth'
                                      sx={{width: 216}}
                                      className={' bg-gray-300'}
                                      defaultValue={data?.checkMonth === '당월' ? 10 : 20}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          checkMonth: e.target.value === 10 ? '당월' : '전월',
                                        })
                                      }}
                                    >
                                      <MenuItem value={10}>당월</MenuItem>
                                      <MenuItem value={20}>전월</MenuItem>
                                    </Select>
                                    <TextField
                                      variant={'outlined'}
                                      className={!updateFlag ? 'bg-gray-300' : ''}
                                      defaultValue={data?.checkDay}
                                      sx={{width: 90}}
                                      type={'number'}
                                      inputProps={{
                                        readOnly: !updateFlag,
                                      }}
                                      onChange={(e) => {
                                        setCustomerUpdateData({
                                          ...customerUpdateData,
                                          checkDay: e.target.value,
                                        })
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className={
                                    'row col w-100 justify-content-between align-items-center'
                                  }
                                >
                                  <h2 className={'d-flex align-items-center gap-2 '}>
                                    <DoubleArrowIcon /> 메모
                                  </h2>
                                  <TextField
                                    variant={'outlined'}
                                    sx={{width: 500}}
                                    defaultValue={data?.memo}
                                    type={'text'}
                                    className={!updateFlag ? 'mx-2 bg-gray-300' : 'mx-2'}
                                    inputProps={{
                                      readOnly: !updateFlag,
                                    }}
                                    onChange={(e) => {
                                      setCustomerUpdateData({
                                        ...customerUpdateData,
                                        memo: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            </Paper>
                          </div>
                        </div>
                      </div>
                      <div className={'col'}>
                        <div className={'row gap-3 p-3'}>
                          <div
                            id='dashboard-detail-map'
                            style={{width: '100%', height: 300, cursor: 'pointer'}}
                            onClick={() => {
                              window.open(
                                `https://m.map.kakao.com/scheme/search?q=${data.addressRoad}&center=me`,
                                '_blank'
                              )
                            }}
                            className={'rounded-2 border-gray-400 border border-1'}
                          />
                          <div className={'text-end'}>
                            <span className={'text-danger fw-bold text-end'}>
                              지도를 클릭하면 카카오맵으로 이동합니다.
                            </span>
                          </div>
                          <Chip
                            label={
                              <span>
                                연결된 단말기 :
                                <strong className={'text-success'}> {data?.serialNumber}</strong>
                              </span>
                            }
                            variant={'outlined'}
                            sx={{fontSize: '20px', fontWeight: 'bold'}}
                          />
                          <div className={'w-100 border border-gray-400 rounded-3 p-3'}>
                            <div className={'row w-100 mx-0 text-center justify-content-center'}>
                              <div className={'row gap-1 '}>
                                <img
                                  src={envImagePath + data?.filePath}
                                  alt='metering-image'
                                  width={'100%'}
                                  // height={144}
                                />
                                <div className={'mt-2 fw-bold fs-4'}>
                                  <span>
                                    {entimoreController.model === '2'
                                      ? dayjs(data?.efosConnectAt).format(
                                          'YYYY년 MM월 DD일 HH:mm:ss'
                                        )
                                      : dayjs(data?.deviceConnectAt).format(
                                          'YYYY년 MM월 DD일 HH:mm:ss'
                                        )}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={
                                  'd-flex col fw-bold fs-3  align-items-self border border-gray-400 mt-2 border-1 rounded-2'
                                }
                              >
                                <div className={'col-5 row gap-3 align-items-center text-start'}>
                                  <span>단말기 상태</span>
                                  <span>펌웨어</span>
                                  {entimoreController.model !== '2' && <span>신호강도</span>}
                                  <span>서버주기</span>
                                </div>
                                <div className={'col row gap-3 text-start align-items-center'}>
                                  <span>
                                    {entimoreController.model === '2' ? (
                                      entiDate.slice(0, -4) == dayjs().format('YYYYMMDD') ? (
                                        <span className={'text-primary'}>정상</span>
                                      ) : (
                                        <span className={'text-danger'}>점검</span>
                                      )
                                    ) : data?.deviceStatus === true ? (
                                      <span className={'text-primary'}>정상</span>
                                    ) : (
                                      <span className={'text-danger'}>점검</span>
                                    )}
                                  </span>
                                  <span>{data?.deviceVersion}</span>
                                  {entimoreController.model !== '2' && (
                                    <span>{data?.signalStrength}dBm</span>
                                  )}
                                  <span>{data?.serverCycle} H</span>
                                </div>
                              </div>
                            </div>
                            <div className={'mt-5 fs-5 fw-bold ms-4 text-center'}>
                              {entimoreController.model === '2' ? (
                                <span className={'text-primary'}>업데이트 내용이 없습니다.</span>
                              ) : data?.deviceApplyStatus === true ? (
                                <span className={'text-danger'}>
                                  업데이트 대기 중인 단말기 입니다.
                                </span>
                              ) : (
                                <span className={'text-primary'}>업데이트 내용이 없습니다.</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      </Box>
    </Modal>
  )
}
export default CustomerDetailInfoModal
